import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import './index.scss'
import Catering from './Catering'
import OurStory from './OurStory'
import Specials from './Specials'
import NewMenu from './NewMenu'
import Beverage from './Beverage'
import Mothers from './Mothers'
import Wine from './Wine'
import LandingPage from './LandingPage'
import AutoMenu from './AutoMenu'
import Stout from './stout'
import Health from './Health'

import './ThemeToggle/theme_night.scss'
import ThemeToggle from './ThemeToggle'

import CartBar from './CartBar'
export {CartBar as StickyTopBar}

export const customOrderAppPaths = []


class TwoGuysFromItalyGlendale extends Component {
  render() {
    return (
      <div className="twoguysfromitalyglendale">
      </div>
    );
  }
}


export const pages = [
  {path: "/order", label: "Order", component: OrderingHeader},
  {path: "/index.php", label: "Health", component: () => <Redirect to="/health" />, hideFromNav: true},
  // {path: "/index.php?option=com_content&view=frontpage&Itemid=3", label: "Health", component: () => <Redirect to="/menu" />, hidden: true},
  {path: "/health", label: "Health", component: Health},
  {path: "https://www.instagram.com/sushi.dragon/", label: "Instagram", external: true,},
  // {path: "/about", label: "About", component: OurStory},
  // {path: "/menu", label: "Menu", component: OrderingHeader},
  // {path: "/specials", label: "Specials", component: Specials},
  // {path: "/mothers", label: "Mothers", component: Mothers},
  // {path: "/drinks", label: "Drinks", component: Beverage},
  // {path: "/wine", label: "WineSale", component: Wine},
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/wp-content/uploads/2018/05/MENU_NEW_012618.pdf", hidden: true, component: () => <Redirect to="/menu" />},
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/about", label: "Our Story", component: OurStory},
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
      {pages
          .filter(({hideFromNav}) => !hideFromNav)
          .map(({path, label, external, onClick}) => (
          <li key={path}>
            {external ?
              <a       className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        ))}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="page">
        <div
          className="page-header">

        <div className="text">
            <h1>Full Menu</h1>
          <p></p>
        </div>

          {/* <div className="logo-wrapper">
            <Stout className="stout-header"></Stout>
          </div> */}
        </div>
        <AutoMenu subMenuFilter={subMenuFilter}></AutoMenu>
      </div>
    );
  }
}

const sections = [
  {
    title: "Happy Hour",
    content: "Every Day 3pm-7pm. All Day Saturday",
    backgroundImage: ""
  }
]

// logo link: https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny
export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">

      <LandingPage></LandingPage>










      </div>
    );
  }
}

const foodImages = [
  'https://afag.imgix.net/two-guys-from-italy-glendale/meat-balls-pasta.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/porchiuto.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/pizza.jpg?w=800&auto=compress',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_d.jpg',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_e.jpg'
]


export function subMenuFilter (subMenu) {
  // return true
  const tag = subMenu.tag || ''
  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isLunchMenu = tag.indexOf('lunch') !== -1
  const isDinnerMenu = tag.indexOf('dinner') !== -1

  console.log('hours', hours)

  if (isLunchMenu) {
    if ((hours <= 14) || (hours >= 21)) {
      return true
    }
    return false
  }

  if (isDinnerMenu) {
    if ((hours >= 14) && (hours <= 21)) {
      return true
    }
    return false
  }

  return true
}

export default TwoGuysFromItalyGlendale;
