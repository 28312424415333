
import * as Components from "./Dragon"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "dragon"
}

